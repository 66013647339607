.container {
  position: relative;
  width: 50%;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0.5rem 3rem 1rem 3rem;
  .close_button {
    position: fixed;
    right: 2px;
    top: 0;
    color: red;
    &:hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
  .image_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border-width: 2px;
    }
    .image_input {
      opacity: 0;
      width: 0.1px;
      height: 0.1px;
      position: absolute;
    }
    & label {
      display: block;
      position: relative;
      width: 100px;
      height: 25px;
      border-radius: 25px;
      background: red;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      font-size: small;
      margin: auto;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: transform 0.2s ease-out;
    }
  }
  .name_number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
