.container {
  position: sticky;
  margin: auto;
  top: 0;
  left: 0;
  z-index: 100;
  height: 15vh;
  width: 100%;
  box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.5);
  background-color: white;
  margin-bottom: 3rem;
  .subcontainer {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    .home {
      position: relative;
      left: 1rem;
      top: 3rem;
      margin: auto 1rem;
      vertical-align: middle;
      color: black;
      text-decoration: none;
      &:hover {
        color: rgb(201, 15, 77);
        cursor: pointer;
      }
    }
  }
  .logo_container {
    width: 100vw;
    display: flex;
    justify-content: center;
    .logo {
      display: inline-block;
      position: relative;
      width: 10rem;
      margin: auto;
      padding: 10px;
      height: inherit;
    }
  }
}
