.popup {
  display: flex;
  flex-direction: column;
  position: relative;
  width: max-content;
  overflow: auto;
  z-index: 1000;
  top: 60%;
  left: 50%;
  height: 40vh;
  transform: translate(-50%, -50%);
  //top: 20%;
  background-color: white;
  padding: 0.5rem 3rem 1rem 3rem;
  .close_button {
    position: fixed;
    font-size: large;
    right: 0;
    top: 0;
    color: red;
    &:hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
  .container {
    display: flex;
    .sub_container {
      margin-left: 1rem;
    }
  }
}
