.switch_tab_container {
  display: inline-block;
  width: 100%;

  .switch_tab {
    margin: auto;
    width: max-content;
    padding: 1rem 0rem;
    text-align: center;
    background-color: gray;
    border-radius: 20px;
    color: white;
    .tab {
      height: fit-content;
      margin: 0 1rem;
      cursor: pointer;
      padding: 1rem;
      padding-left: 1rem;
    }
    .active {
      background-color: palevioletred;
      border-radius: 15px;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  .questions_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .questions {
      margin: 5px;
      box-shadow: none;
      border-radius: 10px;
      border-width: 3px;
      border-style: dotted;
      border-color: black;
    }
  }
  .new_question {
    position: absolute;
    right: 1.5rem;
    //top: 0.5rem;
  }
}

.card_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: max-content;
  height: max-content;
  overflow: auto;
  z-index: 1000;
  top: 60%;
  left: 50%;
  //height: 40vh;
  transform: translate(-50%, -50%);
  //top: 20%;
  background-color: white;
  padding: 0.5rem 3rem 1rem 3rem;
  .close_button {
    position: fixed;
    font-size: large;
    right: 0;
    top: 0;
    color: red;
    &:hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
  .text {
    color: blue;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
