.upload {
  width: 40%;
  margin: auto;
  color: black;
  background-color: azure;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .sub_container {
    .title {
      vertical-align: middle;
      margin-bottom: 1rem;
    }
    .image {
      width: 40%;
    }
    .image_thumbnail {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
    }
    .delete {
      position: relative;
      color: red;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
