.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  .sub_container {
    border-radius: 20px;
    border: 1px dotted black;
    padding: 10px;
    .image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    .data {
      display: grid;
      grid-template-columns: 0.4fr 0.6fr;
      margin: 1rem auto;
    }
  }
}
