.heading {
  width: 30%;
  display: block;
  margin: 2rem auto;
}
.title {
  display: block;
  margin: auto;
  text-align: center;
}
