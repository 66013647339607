.course {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .course_name {
    width: fit-content;
    margin: 0;
  }
  .course_details {
    display: inline-flex;
    flex-direction: row;

    justify-content: flex-end;
    .price {
      margin-right: 1rem;
      display: inline;
      flex-direction: column;
    }
  }
}

.sub_header {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.class {
  margin: 1rem 3rem 1rem 3rem;
  .assignments_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .correct_answer {
      color: green;
    }
    .assignment {
      margin: 5px;
      box-shadow: none;
      border-radius: 10px;
      border-width: 3px;
      border-style: dotted;
      border-color: black;
      .answer_container {
        display: flex;
        flex-direction: row;
        .answers {
          margin-right: 0.4rem;
          margin-left: 0.4rem;
        }
      }
    }
  }
  .sub_container {
    display: flex;
    justify-content: space-between;
  }
  .video_container {
    width: 50%;
    display: flex;
    margin: auto;
  }
}

.popup {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0.5rem 3rem 1rem 3rem;
  .close_button {
    position: fixed;
    right: 2px;
    top: 0;
    color: red;
    &:hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
  }
}
.upload {
  width: 40%;
  margin: auto;
  color: black;
  background-color: azure;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
