.container {
  position: relative;
  width: 50vw;
  height: min-content;
  z-index: 1000;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem 3rem 2rem 3rem;
  overflow-y: scroll;
  .close_button {
    position: fixed;
    right: 2px;
    top: 0;
    color: red;
    &:hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
  .sub_container {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    .image_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 200px;
        height: auto;
        margin-right: 1rem;
        border-width: 2px;
      }
      .image_input {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
      }
      & label {
        display: block;
        position: relative;
        width: 100px;
        height: 25px;
        border-radius: 25px;
        background: red;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        font-size: small;
        margin: auto;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        transition: transform 0.2s ease-out;
      }
    }
    .form_fields {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
    }
  }
}
