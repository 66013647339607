.switch_tab_container {
  display: inline-block;
  width: 100%;

  .switch_tab {
    margin: auto;
    width: max-content;
    padding: 1rem 0rem;
    text-align: center;
    background-color: gray;
    border-radius: 20px;
    color: white;
    .tab {
      height: fit-content;
      margin: 0 1rem;
      cursor: pointer;
      padding: 1rem;
      padding-left: 1rem;
    }
    .active {
      background-color: palevioletred;
      border-radius: 15px;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  .questions_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .questions {
      margin: 5px;
      box-shadow: none;
      border-radius: 10px;
      border-width: 3px;
      border-style: dotted;
      border-color: black;
    }
  }
  .new_question {
    position: absolute;
    right: 1.5rem;
    //top: 0.5rem;
  }
}
