.container {
  display: flex;
  padding: 1rem;
  font-size: larger;
  font-weight: bold;
  justify-content: space-between;
  .subjectName {
    margin: auto 0;
  }
}
