.container {
  position: relative;
  width: 50%;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0.5rem 3rem 1rem 3rem;
  .close_button {
    position: fixed;
    right: 2px;
    top: 0;
    color: red;
    &:hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
  }
}
